html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  height: 100%;
}

html,
body {
  height: 100%;
}
body {
  // overflow-y: scroll;
  color: #eaeaee;
  background: #353438;
}

a {
  color: #009f86;
  &:hover {
    color: #00b398;
  }
}
.reactour__helper {
  > div[data-tour-elem='controls'] {
    justify-content: space-between;
  }
}
